import React from "react";
import SVG_STARS from "../../../assets/images/vector/pages/main/ChromeStoreRating/incomplete_stars.svg";
import "./index.scss";

const ChromeStoreRating = () => (
  <section className="PgEM-CSR">
    <div className="PgEM-CSR-content">
      <div className="PgEM-CSR-profitable">
        <p className="PgEM-CSR-profitable__title">5M+</p>
        <p className="PgEM-CSR-profitable__text">profitable products found</p>
      </div>
      <div className="PgEM-CSR-coaches">
        <p className="PgEM-CSR-coaches__title">100+</p>
        <p className="PgEM-CSR-coaches__text">
          Amazon coaches use for teaching
        </p>
      </div>
      <div className="PgEM-CSR-sellers">
        <p className="PgEM-CSR-sellers__title">500K+</p>
        <p className="PgEM-CSR-sellers__text">sellers have chosen us</p>
      </div>
      <div className="PgEM-CSR-note">
        <img
          className="PgEM-CSR-note__stars"
          src={SVG_STARS}
          alt="*****"
          loading="lazy"
        />
        <p className="PgEM-CSR-note__text">Chrome Store Rating</p>
      </div>
    </div>
  </section>
);

export default ChromeStoreRating;
