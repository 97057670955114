import React from "react";
import { COUNTRIES } from "../index.content";
import Country from "./Country";
import "./index.scss";

const Countries = () => {
  const { firstList, secondList } = COUNTRIES;
  return (
    <div className="PgEM-PV-C">
      <div className="PgEM-PV-C-item">
        {firstList.map(({ name, flag }) => (
          <Country key={name} name={name} flag={flag} />
        ))}
      </div>
      <div className="PgEM-PV-C-item">
        {secondList.map(({ name, flag }) => (
          <Country key={name} name={name} flag={flag} />
        ))}
      </div>
    </div>
  );
};

export default Countries;
