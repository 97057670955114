import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import { getTrueClasses } from "../../../helpers";
import Container from "../../common/UI/Container";
import Description from "./Description";
import Offer from "./Offer";
import Screen from "./Screen";
import FormContainer from "../../../containers/common/forms/FormContainer";
import FormIntro from "./FormIntro";
import "./index.scss";

const HomeIntro = (props) => {
  const {
    isPaid,
    pageAuthState,
    setIsSecondStateForm,
    homeIntroRef,
    handleSuccess,
  } = props;
  const option = {
    redirectButton: {
      category: "main_page",
      buttonText: isPaid
        ? "Go to your AMZScout account"
        : "Try AMZScout for Free!",
    },
  };

  return (
    <section
      ref={homeIntroRef}
      className={getTrueClasses("PgEM-HI", pageAuthState && "PgEM-HI_auth")}
      id="home"
    >
      <Container mode="standard">
        <div className="PgEM-HI-content">
          <Description />
          <div className="PgEM-HI-form">
            <FormContainer
              page={PAGES.MAIN_PAGE}
              pageAuthState={pageAuthState}
              updatePageAuthState={setIsSecondStateForm}
              onSuccess={handleSuccess}
            >
              <FormIntro option={option} />
            </FormContainer>
          </div>
          <Screen />
          <Offer pageAuthState={pageAuthState} isPaid={isPaid} />
        </div>
      </Container>
    </section>
  );
};

HomeIntro.propTypes = {
  pageAuthState: PropTypes.bool,
  setIsSecondStateForm: PropTypes.func,
  setIsPaid: PropTypes.func,
  isPaid: PropTypes.bool,
};

export default HomeIntro;
