import React, { useEffect, useRef, useState } from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import { isSavedUserInfo, handleCheckLicenseForm } from "../helpers";
import { PAGES } from "../helpers/constants";
import {
  BOTTOM_OPTIONS_FORM,
  CENTER_OPTIONS_FORM,
  // DEALS_BANNER_CONTAINER_PROPS,
} from "../components/main/index.content";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/main/HomeIntro";
import ChromeStoreRating from "../components/main/ChromeStoreRating";
import Brands from "../components/common/Brands";
import RatingOverview from "../components/main/RatingOverview";
import Testimonials from "../components/main/Testimonials";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import ProValue from "../components/main/ProValue";
import Partners from "../components/main/Partners";
import Confession from "../components/main/Confession";
import ProInstall from "../components/main/ProInstall";
import Support from "../components/main/Support";
import SuccessStories from "../components/main/SuccessStories";
import Guides from "../components/main/Guides";
import Idea from "../components/main/Idea";
// import DealsBannerContainer from "../containers/common/DealsBannerContainer";
// import DealsStaticBannerContainer from "../containers/common/DealsStaticBannerContainer";
// import SimpleDeals from "../components/common/SimpleDeals";
// import EmptySpace from "../components/main/EmptySpace";
import FormContainer from "../containers/common/forms/FormContainer";
import FormPage from "../components/common/Forms/FormPage";
import "../components/main/index.scss";

const PgEMain = () => {
  const [isPaid, setIsPaid] = useState(false);
  const [isSecondStateForm, setIsSecondStateForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const [isOpacity, setIsOpacity] = useState(true);
  // const [isShowStaticBanner, setIsShowStaticBanner] = useState(true);
  const pricingRef = useRef(null);
  const homeIntroRef = useRef(null);

  const formPropsData = {
    page: PAGES.MAIN_PAGE,
    pageAuthState: !isLoading && isSecondStateForm,
    updatePageAuthState: setIsSecondStateForm,
  };
  const closeYoutubeModal = () => setUrlYoutubeModal("");

  const handleSuccess = () => {
    handleCheckLicenseForm(setIsPaid, setIsOpacity);
  };

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  useEffect(() => {
    setIsSecondStateForm(isSavedUserInfo());
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isSavedUserInfo()) {
      handleCheckLicenseForm(setIsPaid, setIsOpacity);
    } else {
      setIsOpacity(false);
    }
  }, []);

  return (
    <Layout
      customClass="PgEM"
      isShowYoutubeModal={isShowYoutubeModal}
      tariffsElement={pricingRef}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      isLoading={isLoading}
      page={PAGES.MAIN_PAGE}
      // isSimpleTopPanel
      isShowLanguageSwitcher
    >
      <Seo
        title="AMZScout | Amazon Product Research Tool & FBA Sellers Software"
        description="Use AMZScout and make Amazon product research easy with accurate research tools and seller software: AMZScout PRO Chrome Extension, Product Database, Keyword Search and more. Analyze niches and find the right product to make money. Start your free trial."
        page=""
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
      />
      <HomeIntro
        isPaid={isPaid}
        pageAuthState={!isLoading && isSecondStateForm}
        setIsSecondStateForm={setIsSecondStateForm}
        isOpacity={isOpacity}
        homeIntroRef={homeIntroRef}
        handleSuccess={handleSuccess}
      />
      {/* <DealsStaticBannerContainer*/}
      {/*  isShowStaticBanner={isShowStaticBanner}*/}
      {/*  setIsShowStaticBanner={setIsShowStaticBanner}*/}
      {/*  homeIntroRef={homeIntroRef}*/}
      {/*  {...DEALS_BANNER_CONTAINER_PROPS}*/}
      {/* >*/}
      {/*  <SimpleDeals hideCloseButton isStaticDeals />*/}
      {/* </DealsStaticBannerContainer>*/}
      <ChromeStoreRating />
      <Brands />
      <RatingOverview isTrustWidget />
      <CustomizableContainer
        pricingRef={pricingRef}
        customClass="PgEM-Pricing"
        subTitlePro
        // hideStickyBlock={process.env.DEALS_SECTION === "true"}
      />
      <Testimonials setUrlYoutubeModal={setUrlYoutubeModal} />
      <ProValue />
      <FormContainer {...formPropsData}>
        <FormPage option={CENTER_OPTIONS_FORM} />
      </FormContainer>
      <Partners />
      <Confession />
      <ProInstall />
      <Support />
      <SuccessStories />
      <Idea />
      <Guides />
      <FormContainer {...formPropsData}>
        <FormPage option={BOTTOM_OPTIONS_FORM} />
      </FormContainer>
      {/* <EmptySpace isShow={process.env.DEALS_SECTION} />*/}
      {/* <DealsBannerContainer*/}
      {/*  alwaysShowDeals*/}
      {/*  multipleBannerOptions*/}
      {/*  isShowStaticBanner={isShowStaticBanner}*/}
      {/*  {...DEALS_BANNER_CONTAINER_PROPS}*/}
      {/* >*/}
      {/*  <SimpleDeals hideCloseButton />*/}
      {/* </DealsBannerContainer>*/}
    </Layout>
  );
};

export default PgEMain;
