import React, { useEffect, useState, useContext, useRef } from "react";
import {
  PAGE_OPTIONS,
  PAGES,
  TEXT_PLEASE_TRY_AGAIN,
} from "../../../helpers/constants";
import PropTypes from "prop-types";
import { MediaContext } from "../../../store/context/media-context";
import { LayoutContext } from "../../../store/context/layout-context";
import EmailCollectAnalyticsController from "../../../controllers/common/emailCollectAnalyticsController";
import {
  checkValue,
  isSavedUserInfo,
  sendEventsCollectEmail,
} from "../../../helpers";
import { targetStatus } from "../../../helpers/browser";

const FormContainer = (props) => {
  const {
    children,
    page,
    customLocalSave,
    onSuccess,
    pageAuthState,
    updatePageAuthState,
    redirect = true,
    customRedirectUrl,
    operation = false,
    customType,
    customLabel,
    extAction = false,
    extFunc,
    language = "en",
  } = props;
  const [getFormState, setFormState] = useState({
    email: "",
    isFirstTry: true,
    isLoading: false,
    disabled: false,
    error: "",
    isEmpty: true,
    opacity: 0,
  });
  const { isTabletOrLess } = useContext(MediaContext);
  const context = useContext(LayoutContext);
  const countRef = useRef(context);
  countRef.current = context;
  const analytics = new EmailCollectAnalyticsController(customLabel, page);

  useEffect(() => {
    countRef.current.updatePageFormState(
      customLocalSave
        ? !!localStorage.getItem(customLocalSave[0])
        : isSavedUserInfo()
    );
    updateFormState("opacity", 1);
  }, [context]);

  useEffect(() => {
    updatePageAuthState &&
      updatePageAuthState(
        customLocalSave
          ? !!localStorage.getItem(customLocalSave[0])
          : isSavedUserInfo()
      );
  }, []);

  const urlRedirect =
    typeof PAGE_OPTIONS[PAGES[[page]]].REDIRECTED_URL === "object"
      ? PAGE_OPTIONS[PAGES[[page]]].REDIRECTED_URL[
          isTabletOrLess ? "MOBILE" : "DESKTOP"
        ]
      : PAGE_OPTIONS[PAGES[[page]]].REDIRECTED_URL;

  const updateFormState = (name, value) => {
    setFormState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const textPleaseTryAgain =
    TEXT_PLEASE_TRY_AGAIN[language] || TEXT_PLEASE_TRY_AGAIN.en;

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const isEmpty = !value.length;
    const errorResult = checkValue("email", value, false, language);
    updateFormState("email", value);
    updateFormState("isEmpty", isEmpty);
    if (!getFormState.isFirstTry) {
      updateFormState("error", errorResult);
      updateFormState("disabled", !!errorResult);
    }
  };

  const defaultSave = (email) => {
    localStorage.setItem("emailCollect", "true");
    localStorage.setItem("collectData", email);
  };

  const handleEmailSubmit = async (e) => {
    const { captcha, token, isInjected, injection } = countRef.current;
    if (e) {
      e.preventDefault();
    }
    if (!isInjected) {
      injection(true);
    }
    const isEmpty = !getFormState.email.length;
    const errorResult = checkValue(
      "email",
      getFormState.email,
      false,
      language
    );
    updateFormState("isEmpty", isEmpty);
    updateFormState("error", errorResult);
    if (!errorResult) {
      if (
        typeof window !== "undefined" &&
        !window.location.href.includes("localhost") &&
        !token
      ) {
        if (!captcha.current) {
          updateFormState("isLoading", true);
          return setTimeout(() => {
            handleEmailSubmit();
          }, 500);
        } else {
          await captcha.current.executeAsync();
          updateFormState("isLoading", false);
        }
      }
      updateFormState("isLoading", true);
      const isOperation =
        operation || !!PAGE_OPTIONS[PAGES[page]].CLICK_FORM_OPERATION;
      const typeOrOperationValue = isOperation
        ? customType || PAGE_OPTIONS[PAGES[page]].CLICK_FORM_OPERATION
        : PAGE_OPTIONS[PAGES[page]].CLICK_FORM_TYPE;
      const software = isOperation
        ? PAGE_OPTIONS[PAGES[page]].SOFTWARE
        : undefined;

      sendEventsCollectEmail(
        getFormState.email,
        typeOrOperationValue,
        isOperation,
        software
      )
        .then((response) => {
          if (response.ok) {
            customLocalSave
              ? localStorage.setItem(customLocalSave[0], customLocalSave[1])
              : defaultSave(getFormState.email);
            updateFormState("error", "");
            updateFormState("email", "");
            updatePageAuthState
              ? updatePageAuthState(true)
              : countRef.current.updatePageFormState(true);
            onSuccess && onSuccess();
            analytics.sendEmailSubmitSuccessEvent();
            redirect &&
              window.open(
                customRedirectUrl ? customRedirectUrl : urlRedirect,
                targetStatus() ? "_blank" : "_self"
              );
            extAction && extFunc();
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((err) => {
          analytics.sendEmailValidationEvent(err.message);
          updateFormState("error", textPleaseTryAgain);
          updateFormState("disabled", true);
        })
        .finally(() => {
          updateFormState("isLoading", false);
          updateFormState("isFirstTry", false);
        });
    } else {
      analytics.sendEmailValidationEvent(getFormState.email.length);
      // updateFormState("error", textPleaseTryAgain);
      updateFormState("disabled", true);
      updateFormState("isFirstTry", false);
    }
  };

  const handleKeyUp = (e) => {
    const { injection, isInjected } = countRef.current;

    if (e.keyCode === 13) {
      !getFormState.disabled && handleEmailSubmit(e);
    }
    if (e.target.value.length > 0 && !isInjected) {
      injection(true);
    }
  };
  return (
    <>
      {React.cloneElement(children, {
        formOpacity: getFormState.opacity,
        isAuth: pageAuthState ? pageAuthState : countRef.current.pageFormState,
        redirectUrl: customRedirectUrl ? customRedirectUrl : urlRedirect,
        formCalculatedData: {
          emailValue: getFormState.email,
          isEmpty: getFormState.isEmpty,
          onError: getFormState.error,
          disabled: getFormState.disabled || getFormState.isLoading,
          onEmailChange: handleEmailChange,
          onKey: handleKeyUp,
          onSubmit: handleEmailSubmit,
        },
      })}
    </>
  );
};

FormContainer.propTypes = {
  children: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
  customLocalSave: PropTypes.array,
  onSuccess: PropTypes.func,
  pageAuthState: PropTypes.bool,
  updatePageAuthState: PropTypes.func,
  redirect: PropTypes.bool,
  operation: PropTypes.bool,
  customRedirectUrl: PropTypes.string,
  customType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormContainer;
