import React from "react";
import PropTypes from "prop-types";
import OFFER_CONTENT from "./index.content";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const Offer = ({ pageAuthState, isPaid }) => {
  const { unauthorized, trial, paid } = OFFER_CONTENT;
  const { list, status, title } = pageAuthState
    ? isPaid
      ? paid
      : trial
    : unauthorized;

  return (
    <div
      className={getTrueClasses("PgEM-HI-O", status && `PgEM-HI-O_${status}`)}
    >
      <p className="PgEM-HI-O__title">{title}</p>
      <ol className="PgEM-HI-O-list">
        {list.map((text, i) => (
          <li key={i} className="PgEM-HI-O-item">
            <div className="PgEM-HI-O-item__num">
              <span>{i + 1}</span>
            </div>
            <p className="PgEM-HI-O-item__text">{text}</p>
          </li>
        ))}
      </ol>
    </div>
  );
};
Offer.propTypes = {
  pageAuthState: PropTypes.bool,
  isPaid: PropTypes.bool,
};

export default Offer;
