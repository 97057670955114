import React from "react";

import PNG_One from "../../../assets/images/raster/pages/main/ProValue/ProValue_1.png";
import PNG_One_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_1@1200.png";
import PNG_One_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_1@992.png";
import PNG_One_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_1@768.png";
import PNG_One_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_1@mob.png";

import WEBP_One from "../../../assets/images/raster/pages/main/ProValue/ProValue_1.webp";
import WEBP_One_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_1@1200.webp";
import WEBP_One_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_1@992.webp";
import WEBP_One_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_1@768.webp";
import WEBP_One_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_1@mob.webp";

import PNG_Two from "../../../assets/images/raster/pages/main/ProValue/ProValue_2.png";
import PNG_Two_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_2@1200.png";
import PNG_Two_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_2@992.png";
import PNG_Two_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_2@768.png";
import PNG_Two_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_2@mob.png";

import WEBP_Two from "../../../assets/images/raster/pages/main/ProValue/ProValue_2.webp";
import WEBP_Two_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_2@1200.webp";
import WEBP_Two_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_2@992.webp";
import WEBP_Two_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_2@768.webp";
import WEBP_Two_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_2@mob.webp";

import PNG_Three from "../../../assets/images/raster/pages/main/ProValue/ProValue_3.png";
import PNG_Three_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_3@1200.png";
import PNG_Three_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_3@992.png";
import PNG_Three_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_3@768.png";
import PNG_Three_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_3@mob.png";

import WEBP_Three from "../../../assets/images/raster/pages/main/ProValue/ProValue_3.webp";
import WEBP_Three_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_3@1200.webp";
import WEBP_Three_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_3@992.webp";
import WEBP_Three_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_3@768.webp";
import WEBP_Three_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_3@mob.webp";

import PNG_Four from "../../../assets/images/raster/pages/main/ProValue/ProValue_4.png";
import PNG_Four_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_4@1200.png";
import PNG_Four_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_4@992.png";
import PNG_Four_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_4@768.png";
import PNG_Four_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_4@mob.png";

import WEBP_Four from "../../../assets/images/raster/pages/main/ProValue/ProValue_4.webp";
import WEBP_Four_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_4@1200.webp";
import WEBP_Four_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_4@992.webp";
import WEBP_Four_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_4@768.webp";
import WEBP_Four_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_4@mob.webp";

import PNG_Five from "../../../assets/images/raster/pages/main/ProValue/ProValue_5.png";
import PNG_Five_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_5@1200.png";
import PNG_Five_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_5@992.png";
import PNG_Five_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_5@768.png";
import PNG_Five_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_5@mob.png";

import WEBP_Five from "../../../assets/images/raster/pages/main/ProValue/ProValue_5.webp";
import WEBP_Five_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_5@1200.webp";
import WEBP_Five_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_5@992.webp";
import WEBP_Five_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_5@768.webp";
import WEBP_Five_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_5@mob.webp";

import PNG_Six from "../../../assets/images/raster/pages/main/ProValue/ProValue_6.png";
import PNG_Six_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_6@1200.png";
import PNG_Six_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_6@992.png";
import PNG_Six_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_6@768.png";
import PNG_Six_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_6@mob.png";

import WEBP_Six from "../../../assets/images/raster/pages/main/ProValue/ProValue_6.webp";
import WEBP_Six_1200 from "../../../assets/images/raster/pages/main/ProValue/ProValue_6@1200.webp";
import WEBP_Six_992 from "../../../assets/images/raster/pages/main/ProValue/ProValue_6@992.webp";
import WEBP_Six_768 from "../../../assets/images/raster/pages/main/ProValue/ProValue_6@768.webp";
import WEBP_Six_mob from "../../../assets/images/raster/pages/main/ProValue/ProValue_6@mob.webp";

import JPG_US from "../../../assets/images/raster/pages/main/ProValue/flags/us.jpg";
import JPG_US_mob from "../../../assets/images/raster/pages/main/ProValue/flags/us@mob.jpg";

import JPG_JP from "../../../assets/images/raster/pages/main/ProValue/flags/jp.jpg";
import JPG_JP_mob from "../../../assets/images/raster/pages/main/ProValue/flags/jp@mob.jpg";

import JPG_AE from "../../../assets/images/raster/pages/main/ProValue/flags/ae.jpg";
import JPG_AE_mob from "../../../assets/images/raster/pages/main/ProValue/flags/ae@mob.jpg";

import JPG_CA from "../../../assets/images/raster/pages/main/ProValue/flags/ca.jpg";
import JPG_CA_mob from "../../../assets/images/raster/pages/main/ProValue/flags/ca@mob.jpg";

import JPG_DE from "../../../assets/images/raster/pages/main/ProValue/flags/de.jpg";
import JPG_DE_mob from "../../../assets/images/raster/pages/main/ProValue/flags/de@mob.jpg";

import JPG_FR from "../../../assets/images/raster/pages/main/ProValue/flags/fr.jpg";
import JPG_FR_mob from "../../../assets/images/raster/pages/main/ProValue/flags/fr@mob.jpg";

import JPG_UK from "../../../assets/images/raster/pages/main/ProValue/flags/uk.jpg";
import JPG_UK_mob from "../../../assets/images/raster/pages/main/ProValue/flags/uk@mob.jpg";

import JPG_MX from "../../../assets/images/raster/pages/main/ProValue/flags/mx.jpg";
import JPG_MX_mob from "../../../assets/images/raster/pages/main/ProValue/flags/mx@mob.jpg";

import JPG_IT from "../../../assets/images/raster/pages/main/ProValue/flags/it.jpg";
import JPG_IT_mob from "../../../assets/images/raster/pages/main/ProValue/flags/it@mob.jpg";

import JPG_ES from "../../../assets/images/raster/pages/main/ProValue/flags/es.jpg";
import JPG_ES_mob from "../../../assets/images/raster/pages/main/ProValue/flags/es@mob.jpg";

import JPG_SA from "../../../assets/images/raster/pages/main/ProValue/flags/sa.jpg";
import JPG_SA_mob from "../../../assets/images/raster/pages/main/ProValue/flags/sa@mob.jpg";

import JPG_IN from "../../../assets/images/raster/pages/main/ProValue/flags/in.jpg";
import JPG_IN_mob from "../../../assets/images/raster/pages/main/ProValue/flags/in@mob.jpg";
import TrackedLink from "../../Analytics/TrackedLink";

const Link = ({ text, path }) => (
  <TrackedLink
    classes="PgEM-PV-D__link"
    category="main_page"
    action={text}
    path={path}
  >
    {text}
  </TrackedLink>
);
const LINK_PROPS = {
  PRODUCT_DATABASE: {
    text: "Product Database",
    path: "/product-database/?r=main_site_t",
  },
  PRO_EXTENSION: {
    text: "PRO Extension",
    path: "/pro/?r=main_site_t",
  },
  PROFITABLE_PRODUCTS: {
    text: "profitable products",
    path: "/app/#/reports/insights?r=main_site_t",
  },
  STEP_BY_STEP_VIDEO_COURSES: {
    text: "step-by-step video courses",
    path: "/amazon-private-label-course?r=main_site_t",
  },
  MASTERCLASSES: {
    text: "masterclasses",
    path: "/free-amazon-fba-masterclasses?r=main_site_t",
  },
  LIVE_WORKSHOPS: {
    text: "LIVE workshops",
    path: "https://learn.amzscout.net/free-masterclass?r=main_site_t",
  },
  SPOT_PRODUCTS_EASILY: {
    text: "Spot products easily",
    path: "/amazon-toolkit/?r=main_site_t",
  },
  PRODUCTS_NEED_ADDITIONAL_INVESTMENT: {
    text: "products need additional investment",
    path: "/online-arbitrage/?r=main_site_t",
  },
  AFFORDABLE_AND_TRUSTWORTHY_SUPPLIERS: {
    text: "affordable and trustworthy suppliers",
    path: "/pro/?r=main_site_t",
  },
  AMZSCOUT_KEYWORD_TOOLS: {
    text: "AMZScout keyword tools",
    path: "/amazon-keyword-tools/?r=main_site_t",
  },
};

const BUTTONS = {
  PRODUCT_DATABASE: {
    text: "Product Database",
    path: "/app/#/database?r=main_site_p",
  },
  WEEKLY_HOT_PRODUCTS: {
    text: "Weekly Hot Products",
    path: "/app/#/reports/insights?r=main_site_p",
  },
  COURSES: {
    text: "Courses",
    path: "/amazon-private-label-course?r=main_site_p",
  },
  MASTERCLASSES: {
    text: "Masterclasses",
    path: "/free-amazon-fba-masterclasses?r=main_site_p",
  },
  LIVE_WORKSHOPS: {
    text: "LIVE Workshops",
    path: "https://learn.amzscout.net/free-masterclass?r=main_site_p",
  },
  INSTALL_DROPSHIPPING_ARBITRAGE_AND_WHOLESALE_EXTENSION: {
    text: "Install Dropshipping, Arbitrage, and Wholesale Extension",
    path: "https://chromewebstore.google.com/detail/amazon-dropshipping-arbit/edkgpjhfpfpgkohafpaliolcfnijnibh?utm_source=site&utm_medium=amzscout&utm_campaign=main_site_p",
    target: true,
  },
  INSTALL_PRO_EXTENSION_FOR_CHROME_AND_EDGE: {
    text: "Install PRO Extension for Chrome and Edge",
    path: "https://chromewebstore.google.com/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea?utm_source=site&utm_medium=amzscout&utm_campaign=main_site_p",
    target: true,
  },
  AMAZON_KEYWORD_SEARCH: {
    text: "Amazon Keyword Search",
    path: "/app/#/keywords?r=main_site_p",
  },
  PRODUCT_KEYWORDS_2_0: {
    text: "Product Keywords 2.0",
    path: "/app/#/product-keywords?r=main_site_p",
  },
};

export const LIST_OF_ITEMS = [
  {
    screen: {
      PNG: PNG_One,
      PNG_1200: PNG_One_1200,
      PNG_992: PNG_One_992,
      PNG_768: PNG_One_768,
      PNG_mob: PNG_One_mob,
      WEBP: WEBP_One,
      WEBP_1200: WEBP_One_1200,
      WEBP_992: WEBP_One_992,
      WEBP_768: WEBP_One_768,
      WEBP_mob: WEBP_One_mob,
    },
    buttons: [BUTTONS.PRODUCT_DATABASE, BUTTONS.WEEKLY_HOT_PRODUCTS],
    title: "Find Profitable Products to Sell on Amazon",
    isNumberedList: true,
    text: [
      <>Come up with hot new product ideas by using the <Link {...LINK_PROPS.PRODUCT_DATABASE} /> that includes 550 million items</>,
      <>Identify and compare products with the highest profit potential and evaluate competition using the legendary <Link {...LINK_PROPS.PRO_EXTENSION} /></>,
      <>Get <Link {...LINK_PROPS.PROFITABLE_PRODUCTS} /> that perform in the top 1% delivered right to your email</>,
      "Gain access to the most accurate analytics solution in the industry",
    ],
  },
  {
    screen: {
      PNG: PNG_Two,
      PNG_1200: PNG_Two_1200,
      PNG_992: PNG_Two_992,
      PNG_768: PNG_Two_768,
      PNG_mob: PNG_Two_mob,
      WEBP: WEBP_Two,
      WEBP_1200: WEBP_Two_1200,
      WEBP_992: WEBP_Two_992,
      WEBP_768: WEBP_Two_768,
      WEBP_mob: WEBP_Two_mob,
    },
    buttons: [BUTTONS.COURSES, BUTTONS.MASTERCLASSES, BUTTONS.LIVE_WORKSHOPS],
    title: "Learn How to Sell on the Amazon Marketplace",
    text: [
      <>See exactly how to choose profitable products, source items from suppliers, and market your business with <Link {...LINK_PROPS.STEP_BY_STEP_VIDEO_COURSES} /> and <Link {...LINK_PROPS.MASTERCLASSES} /> created by 7-figure Amazon sellers.</>,
      <>Register and attend our <Link {...LINK_PROPS.LIVE_WORKSHOPS} /> with accomplished Amazon sellers and coaches to learn tips and tricks, from opening an Amazon account to implementing new selling strategies for running your existing business.</>
    ],
  },
  {
    screen: {
      PNG: PNG_Three,
      PNG_1200: PNG_Three_1200,
      PNG_992: PNG_Three_992,
      PNG_768: PNG_Three_768,
      PNG_mob: PNG_Three_mob,
      WEBP: WEBP_Three,
      WEBP_1200: WEBP_Three_1200,
      WEBP_992: WEBP_Three_992,
      WEBP_768: WEBP_Three_768,
      WEBP_mob: WEBP_Three_mob,
    },
    buttons: [BUTTONS.INSTALL_DROPSHIPPING_ARBITRAGE_AND_WHOLESALE_EXTENSION],
    title: "Make High Profits Selling Proven Profitable Products",
    isNumberedList: true,
    text: [
      <><Link {...LINK_PROPS.SPOT_PRODUCTS_EASILY} /> that are suitable for dropshipping, arbitrage, and wholesale</>,
      "Calculate the price needed to own the Buy Box, and how many sales you can make",
      <>Find out which <Link {...LINK_PROPS.PRODUCTS_NEED_ADDITIONAL_INVESTMENT} /> and special permissions to sell with ease</>,
    ],
  },
  {
    screen: {
      PNG: PNG_Four,
      PNG_1200: PNG_Four_1200,
      PNG_992: PNG_Four_992,
      PNG_768: PNG_Four_768,
      PNG_mob: PNG_Four_mob,
      WEBP: WEBP_Four,
      WEBP_1200: WEBP_Four_1200,
      WEBP_992: WEBP_Four_992,
      WEBP_768: WEBP_Four_768,
      WEBP_mob: WEBP_Four_mob,
    },
    buttons: [BUTTONS.INSTALL_PRO_EXTENSION_FOR_CHROME_AND_EDGE],
    title: "Search for Local and International Suppliers on Alibaba",
    isNumberedList: true,
    text: [
      <>Discover <Link {...LINK_PROPS.AFFORDABLE_AND_TRUSTWORTHY_SUPPLIERS} /> from any country, including the US and China, in just one click</>,
      "Gain access to millions of manufacturers that are ready to create your private label products",
      "Find your competitor’s products on Alibaba.com and add them to your inventory to scale your business",
      "Compare suppliers by rating and business activity to choose the most reliable partners for your business",
    ],
  },
  {
    screen: {
      PNG: PNG_Five,
      PNG_1200: PNG_Five_1200,
      PNG_992: PNG_Five_992,
      PNG_768: PNG_Five_768,
      PNG_mob: PNG_Five_mob,
      WEBP: WEBP_Five,
      WEBP_1200: WEBP_Five_1200,
      WEBP_992: WEBP_Five_992,
      WEBP_768: WEBP_Five_768,
      WEBP_mob: WEBP_Five_mob,
    },
    buttons: [BUTTONS.AMAZON_KEYWORD_SEARCH, BUTTONS.PRODUCT_KEYWORDS_2_0],
    title: "Launch a Successful Product Quickly",
    isNumberedList: true,
    text: [
      <>Get all the keywords you need to create effective product listings and PPC campaigns with <Link {...LINK_PROPS.AMZSCOUT_KEYWORD_TOOLS} /></>,
      "Receive reports to help you fine-tune your listings and beat your competitors",
      "Build the perfect listings and PPC campaigns that allow you to hack Amazon’s search algorithm and boost your sales",
      "Quickly find suppliers for any new product by using in-tool Alibaba suggestions",
    ],
  },
  {
    screen: {
      PNG: PNG_Six,
      PNG_1200: PNG_Six_1200,
      PNG_992: PNG_Six_992,
      PNG_768: PNG_Six_768,
      PNG_mob: PNG_Six_mob,
      WEBP: WEBP_Six,
      WEBP_1200: WEBP_Six_1200,
      WEBP_992: WEBP_Six_992,
      WEBP_768: WEBP_Six_768,
      WEBP_mob: WEBP_Six_mob,
    },
    buttons: [BUTTONS.INSTALL_PRO_EXTENSION_FOR_CHROME_AND_EDGE],
    title: "Analyze a Competitor’s Data",
    text: [
      "Monitor their sales, pricing, keywords, and stock levels to gain an advantage."
    ],
  },
];


export const COUNTRIES = {
  firstList: [
    { name: "USA", flag: { JPG: JPG_US, JPG_mob: JPG_US_mob } },
    { name: "United Kingdom", flag: { JPG: JPG_UK, JPG_mob: JPG_UK_mob } },
    { name: "Germany", flag: { JPG: JPG_DE, JPG_mob: JPG_DE_mob } },
    { name: "France", flag: { JPG: JPG_FR, JPG_mob: JPG_FR_mob } },
    { name: "Italy", flag: { JPG: JPG_IT, JPG_mob: JPG_IT_mob } },
    { name: "Spain", flag: { JPG: JPG_ES, JPG_mob: JPG_ES_mob } },
  ],
  secondList: [
    { name: "Canada", flag: { JPG: JPG_CA, JPG_mob: JPG_CA_mob } },
    { name: "Mexico", flag: { JPG: JPG_MX, JPG_mob: JPG_MX_mob } },
    { name: "India", flag: { JPG: JPG_IN, JPG_mob: JPG_IN_mob } },
    { name: "Japan", flag: { JPG: JPG_JP, JPG_mob: JPG_JP_mob } },
    { name: "UAE", flag: { JPG: JPG_AE, JPG_mob: JPG_AE_mob } },
    { name: "Saudi Arabia", flag: { JPG: JPG_SA, JPG_mob: JPG_SA_mob } },
  ],
};