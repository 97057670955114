import React from "react";
import SVG_LOGO from "../../../../assets/images/vector/components/RatingOverview/trustpilot_logo.svg";
import "./index.scss";

const Trust = ({ excellentText }) => (
  <div className="PgEM-RO-T">
    <p className="PgEM-RO-T__text">
      <span>‘</span>
      {excellentText}
      <span>’</span>
    </p>
    <img className="PgEM-RO-T__logo" src={SVG_LOGO} alt="." />
  </div>
);

export default Trust;
