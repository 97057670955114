import React from "react";
import "./index.scss";

const Country = ({ name, flag }) => {
  const { JPG, JPG_mob } = flag;
  return (
    <div className="PgEM-PV-C-C">
      <picture>
        {/* <source type="image/webp" media={`(min-width: 768px)`} srcSet={WEBP} />*/}
        <source media="(min-width: 768px)" srcSet={JPG} />
        {/* <source*/}
        {/*  type="image/webp"*/}
        {/*  media={`(max-width: 767px)`}*/}
        {/*  srcSet={WEBP_mob}*/}
        {/* />*/}
        <source media="(max-width: 767px)" srcSet={JPG_mob} />
        <img className="PgEM-PV-C-C__flag" src={JPG} alt="" loading="lazy" />
      </picture>
      <p className="PgEM-PV-C-C__name">{name}</p>
    </div>
  );
};

export default Country;
